<template>
  <b-card>

    <user-edit-tab-account
      :user-data="{}"
      class="mt-2 pt-75"
    />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'

import UserEditTabAccount from './UserEditTabAccount.vue'

export default {
  components: {
    BCard,

    UserEditTabAccount,
  },
}
</script>

<style>

</style>
