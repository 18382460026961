<template>
  <div>

    <div class="row">
      <div class="col-md-4">
        <b-media class="mb-2">
          <template #aside>
            <b-avatar ref="previewEl" :src="user.avatar" :variant="`light-primary`" size="90px" rounded />
          </template>
          <h4 class="mb-1">
            {{ user.first_name }} {{ user.last_name }}
          </h4>
          <div class="d-flex flex-wrap">
            <hwa-file-button label="Upload" :loading="avatarLoading" @onFileUpload="uploadAvatar" />
            <hwa-button label="Remove" :icon="null" :loading="avatarRemoveLoading" variant="outline-secondary"
              class="ml-1" @onClick="removePhoto()" />
          </div>
        </b-media>
      </div>

      <div class="col-md-8">
        <!-- User Info: Input Fields -->
        <div class="mt-2 mb-1">
          <strong>User</strong>
        </div>

        <validation-observer v-slot="{ invalid }">
          <b-row>
            <b-col cols="12" md="6">
              <hwa-input v-model="user.first_name" label="First Name" name="first_name" rules="required" />
            </b-col>
            <b-col cols="12" md="6">
              <hwa-input v-model="user.last_name" label="Last Name" name="last_name" rules="required" />
            </b-col>
            <b-col cols="12" md="6">
              <hwa-input v-model="user.email" label="Email" name="email" rules="required|email" />
            </b-col>
            <b-col cols="12" md="6">
              <hwa-input v-model="user.phone" label="Phone Number" name="phone" />
            </b-col>
            <!-- <b-col cols="12" md="12">
              <hwa-text-area v-model="user.bio" label="Bio" />
            </b-col> -->
          </b-row>

          <b-row v-if="userType==='licensee'">
            <b-col cols="12" md="4">
              <hwa-select v-model="user.licensee_type" :options="licensee_types" label="Licensee Type" name="licensee_type" rules="required"
                :immediate="false" />
            </b-col>
            <b-col cols="12" md="4">
              <hwa-input v-model="user.brokerage_firm" label="Brokerage Firm" name="borkerage_firm" rules="required"
                :immediate="false" />
            </b-col>
            <b-col cols="12" md="4">
              <hwa-input v-model="user.broker_name" label="Supervising Broker" name="broker_name" rules="required"
                :immediate="false" />
            </b-col>
            <b-col cols="12" md="4">
              <hwa-input v-model="user.broker_phone" label="Supervising Broker Phone" name="broker_phone" rules="required"
                :immediate="false" />
            </b-col>
          </b-row>
          <hwa-button label="Save" :disabled="invalid" :loading="userLoading" @onClick="updateUserInfo()" />
        </validation-observer>
        <!--    <div-->
        <!--      v-if="userRole==='staff'"-->
        <!--      class="mt-2"-->
        <!--    >-->
        <!--      <div class="mb-1">-->
        <!--        <strong>Team</strong>-->
        <!--      </div>-->

        <!--      <validation-observer v-slot="{invalid}">-->
        <!--        <b-row>-->
        <!--          <b-col-->
        <!--            cols="12"-->
        <!--            md="4"-->
        <!--          >-->
        <!--            <hwa-input-->
        <!--              v-model="staff.team_name"-->
        <!--              label="Vendor Name"-->
        <!--              name="agent"-->
        <!--              rules="required"-->
        <!--            />-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <!--        <hwa-button-->
        <!--          :loading="teamLoading"-->
        <!--          label="Save"-->
        <!--          :disabled="invalid"-->
        <!--          @onClick="updateTeam()"-->
        <!--        />-->
        <!--      </validation-observer>-->

        <!--    </div>-->
        <!-- Security: Input Fields -->
        <hr>
        <hr>
        <div class="mt-2 mb-1">
          <strong>Security</strong>
        </div>

        <validation-observer v-slot="{ invalid }">
          <b-row>
            <b-col cols="12" md="4">
              <hwa-input v-model="security.old_password" label="Old Password" name="old_password" rules="required"
                type="password" :immediate="false" />
            </b-col>
            <b-col cols="12" md="4">
              <hwa-input v-model="security.password" label="New Password" name="password" rules="required"
                type="password" :immediate="false" />
            </b-col>
            <b-col cols="12" md="4">
              <hwa-input v-model="security.password_confirmation" label="Confirm Password" name="password_confirmation"
                rules="required" type="password" :immediate="false" />
            </b-col>
          </b-row>
          <hwa-button :loading="securityLoading" label="Save" :disabled="invalid" @onClick="updatePassword()" />
        </validation-observer>

      </div>
    </div>

  </div>
</template>

<script>
import {
  BMedia,
  BAvatar,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaSelect from '@/layouts/components/HwaSelect.vue'
import { ValidationObserver } from 'vee-validate'
import HwaFileButton from '@/layouts/components/HwaFileButton.vue'
import showToast from '@/mixins/showToast'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'

export default {
  components: {
    HwaFileButton,
    HwaInput,
    HwaSelect,
    HwaButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    ValidationObserver,
    HwaTextArea,
  },
  mixins: [showToast],
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userLoading: false,
      securityLoading: false,
      avatarLoading: false,
      avatarRemoveLoading: false,
      security: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      licensee_types:[
        "Real Estate Broker",
        "Real Estate Salesperson",
        "Real Estate Associate Broker"
      ],
      user: {
        id: null,
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        bio: '',
        avatar: null,
        licensee_type: null,
        brokerage_firm: null,
        broker_name: null,
        broker_phone: null,
      },
      vendor: {
        vendor_name: '',
      },
      team: {
        team_name: '',
      },
    }
  },
  watch:{
    isBroker(val){
      // if(val){
      //   this.user.broker_name = null;
      //   this.user.broker_phone = null;
      // }else{
      //   this.user.brokerage_firm = null;
      // }
    }
  },
  computed: {
    isBroker(){
      return this.user.licensee_type==="Real Estate Broker"
    },
    userType() {
      return this.$store.getters['auth/userType']
    },
    userRole() {
      return this.$store.getters['auth/role'].toLowerCase()
    },
    isAdmin() {
      return this.userRole === 'admin'
    },
  },
  mounted() {
    this.user = this.$store.getters['auth/user']
  },
  methods: {
    updateUserInfo() {
      this.userLoading = true
      let path = 'profile'
      if (this.isAdmin) {
        path = 'admin/profile'
      }
      this.$http.put('/api/update-user-account', {
        id: this.user.id,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        phone: this.user.phone,
        bio: this.user.bio,
        licensee_type: this.user.licensee_type,
        brokerage_firm: this.user.brokerage_firm,
        broker_name: this.user.broker_name,
        broker_phone: this.user.broker_phone,
      })
        .then(res => {
          this.$store.commit('auth/updateUser', res.data)
          this.showSuccess('Updated Successfully')
          this.userLoading = false
        })
        .catch(err => {
          this.showError('Failed to update information')
          this.userLoading = false
        })
      // send request to update avatar
    },
    updatePassword() {
      this.securityLoading = true
      let path = 'password'
      if (this.isAdmin) {
        path = 'admin/password'
      }
      this.$http.put('/api/password', this.security)
        .then(res => {
          this.$store.commit('auth/updateUser', res.data)
          this.showSuccess('Updated Successfully')
          this.securityLoading = false
        })
        .catch(err => {
          this.showError('Failed to update password')
          this.securityLoading = false
        })
      // send request to update avatar
    },
    uploadAvatar(file) {
      this.avatarLoading = true
      this.user.avatar = file.dataUrl
      const fd = new FormData()
      fd.append('avatar', file.file)
      let path = 'avatar'
      if (this.isAdmin) {
        path = 'admin/avatar'
      }
      this.$http.post('/api/avatar', fd)
        .then(res => {
          this.$store.commit('auth/updateUser', { avatar: res.data })
          this.showSuccess('Updated Successfully')
          this.avatarLoading = false
        })
        .catch(err => {
          this.showError('Failed to update avatar')
          this.avatarLoading = false
        })
      // send request to update avatar
    },
    removePhoto() {
      this.avatarRemoveLoading = true
      let path = 'avatar'
      if (this.isAdmin) {
        path = 'admin/avatar'
      }
      this.$http.delete('/api/avatar')
        .then(res => {
          this.user.avatar = null
          this.$store.commit('auth/updateUser', res.data)
          this.showSuccess('Removed Successfully')
          this.avatarRemoveLoading = false
        })
        .catch(err => {
          this.showError('Failed to remove avatar')
          this.avatarRemoveLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
